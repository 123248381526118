<template>
  <v-app style="background-color:#eee;padding:20px;">
    <base-material-card
      icon="mdi-clipboard-text"
      :title="$t('login')"
      class="px-5 py-3 col-md-5"
      color="primary"
      style="margin:auto;"
    >
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="user.email"
            type="email"
            :label="$t('email')"
            class="purple-input"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="user.password"
            type="password"
            :label="$t('password')"
            class="purple-input"
          />
        </v-col>
        <v-col cols="12" v-if="error" style="color:red">{{ error }}</v-col>
      </v-row>
      <v-row no-gutters>
         <v-col cols="12" class="text-left" sm=6 align-self="center">
          <a @click="reset_password()" color="primary" class="mr-0">{{$t('forgot_password')}}</a>
        </v-col>
        <v-col cols="12" class="text-right" sm=6>
          <v-btn @click="login_user()" color="primary" class="mr-0">{{$t('confirm')}}</v-btn>
        </v-col>
      </v-row>
    </base-material-card>
  </v-app>
</template>

<script>
import * as firebase from "firebase";
import Vue from "vue";
export default {
  data() {
    return {
      user: {
        email: "",
        password: "",
      },
      error: null,
    };
  },
  mounted() {},
  methods: {
    reset_password: function(event) {
      this.$router.push("reset-password");
    },
    login_user: function (event) {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.user.email, this.user.password)
        .then((data) => {
          if (data.user.emailVerified) {
            this.$store
              .dispatch("GET_USER", { id: data.user.uid })
              .then((user) => {
                let u = user.val();
                if (!user.verified) {
                  u.id = data.user.uid;
                  u.verified = true;
                  this.$store.dispatch("SAVE_USER", { user: u });
                }
                this.$store.commit("SET_USER", u);
                this.$router.push("dashboard");
              });
          } else {
            this.$notify({
              group: "foo",
              title: "Error",
              text: "Vašega email naslova še niste potrdili.",
              type: "error",
            });
          }
        })
        .catch((error) => {
          switch (error.code) {
            case "auth/invalid-email":
              this.error = this.$t("invalid_email");
              break;
            case "auth/wrong-password":
              this.error = this.$t("invalid_password");
              break;
            case "auth/user-not-found":
              this.error = this.$t("invalid_user");
              break;
          }
          //this.error = error.message;
        });
    },
  },
};
</script>
